import React from "react";
import CustomDailog from "./Dailog";
import { Typography, IconButton, Grid } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";

const kiloGenericText =
   "Once we wash, dry & fold your items, we weigh them to calculate the cost. Mattress protectors, pillows, doona inners and heavy blankets are charged separately.";

export const ModalSevenKilos = (props) => {
   const { open, handleClose } = props;
   return (
      <CustomDailog open={open} handleClickClose={() => handleClose(false)}>
         <div className="mt-3 mx-4">
            <IconButton
               color="secondary"
               size="small"
               className="d-flex align-items-center bg-white"
               onClick={() => handleClose(false)}
               style={{ position: "absolute", right: 16 }}
            >
               <CloseOutlined />
            </IconButton>
            <Typography variant="h1" className="py-3">
               What fits 7Kg
            </Typography>
            <Typography variant="body1">{kiloGenericText}</Typography>
            <ul className="fm-load-list">
               <li>
                  <CheckIcon />
                  <Typography variant="subtitle1" component="span">
                     8x Tees
                  </Typography>
               </li>
               <li className="elementor-icon-list-item">
                  <CheckIcon />
                  <Typography variant="subtitle1" component="span">
                     2x Jeans
                  </Typography>
               </li>
               <li className="elementor-icon-list-item">
                  <CheckIcon />
                  <Typography variant="subtitle1" component="span">
                     1x Shorts
                  </Typography>
               </li>
               <li className="elementor-icon-list-item">
                  <CheckIcon />
                  <Typography variant="subtitle1" component="span">
                     1x Sweatshirt
                  </Typography>
               </li>
               <li className="elementor-icon-list-item">
                  <CheckIcon />
                  <Typography variant="subtitle1" component="span">
                     2x Leggings
                  </Typography>
               </li>
               <li className="elementor-icon-list-item">
                  <CheckIcon />
                  <Typography variant="subtitle1" component="span">
                     1x Queen fitted sheets
                  </Typography>
               </li>
               <li className="elementor-icon-list-item">
                  <CheckIcon />
                  <Typography variant="subtitle1" component="span">
                     2x Pillowcases
                  </Typography>
               </li>
            </ul>
         </div>
      </CustomDailog>
   );
};

export const ModalEightKilos = (props) => {
   const { open, handleClose } = props;
   return (
      <CustomDailog open={open} handleClickClose={() => handleClose(false)}>
         <div className="mt-3 mx-4">
            <IconButton
               color="secondary"
               size="small"
               className="d-flex align-items-center bg-white"
               onClick={() => handleClose(false)}
               style={{ position: "absolute", right: 16 }}
            >
               <CloseOutlined />
            </IconButton>
            <Typography variant="h1" className="py-3">
               What fits 8Kg
            </Typography>
            <Typography variant="body1">{kiloGenericText}</Typography>
            <ul className="fm-load-list">
               <li>
                  <CheckIcon />
                  <Typography variant="subtitle1" component="span">
                     8x Tees
                  </Typography>
               </li>
               <li className="elementor-icon-list-item">
                  <CheckIcon />
                  <Typography variant="subtitle1" component="span">
                     2x Jeans
                  </Typography>
               </li>
               <li className="elementor-icon-list-item">
                  <CheckIcon />
                  <Typography variant="subtitle1" component="span">
                     1x Shorts
                  </Typography>
               </li>
               <li className="elementor-icon-list-item">
                  <CheckIcon />
                  <Typography variant="subtitle1" component="span">
                     1x Sweatshirt
                  </Typography>
               </li>
               <li className="elementor-icon-list-item">
                  <CheckIcon />
                  <Typography variant="subtitle1" component="span">
                     2x Leggings
                  </Typography>
               </li>
               <li className="elementor-icon-list-item">
                  <CheckIcon />
                  <Typography variant="subtitle1" component="span">
                     1x Queen fitted sheets
                  </Typography>
               </li>
               <li className="elementor-icon-list-item">
                  <CheckIcon />
                  <Typography variant="subtitle1" component="span">
                     2x Pillowcases
                  </Typography>
               </li>
            </ul>
         </div>
      </CustomDailog>
   );
};

export const ModalNineKilos = (props) => {
   const { open, handleClose } = props;
   return (
      <CustomDailog open={open} handleClickClose={() => handleClose(false)}>
         <div className="mt-3 mx-4">
            <IconButton
               color="secondary"
               size="small"
               className="d-flex align-items-center bg-white"
               onClick={() => handleClose(false)}
               style={{ position: "absolute", right: 16 }}
            >
               <CloseOutlined />
            </IconButton>
            <Typography variant="h1" className="py-3">
               What fits 9Kg
            </Typography>
            <Typography variant="body1">{kiloGenericText}</Typography>
            <Grid container spacing={4}>
               <Grid item xs={12} md={6}>
                  <ul className="fm-load-list">
                     <li>
                        <CheckIcon />
                        <Typography variant="subtitle1" component="span">
                           4x Mens tees
                        </Typography>
                     </li>
                     <li>
                        <CheckIcon />
                        <Typography variant="subtitle1" component="span">
                           1x Jeans
                        </Typography>
                     </li>
                     <li>
                        <CheckIcon />
                        <Typography variant="subtitle1" component="span">
                           1x Shorts
                        </Typography>
                     </li>
                     <li>
                        <CheckIcon />
                        <Typography variant="subtitle1" component="span">
                           1x Sweatshirt
                        </Typography>
                     </li>
                     <li>
                        <CheckIcon />
                        <Typography variant="subtitle1" component="span">
                           4x Womens Tees
                        </Typography>
                     </li>
                     <li>
                        <CheckIcon />
                        <Typography variant="subtitle1" component="span">
                           1x Women Jeans
                        </Typography>
                     </li>
                     <li>
                        <CheckIcon />
                        <Typography variant="subtitle1" component="span">
                           1x Leggins
                        </Typography>
                     </li>
                     <li>
                        <CheckIcon />
                        <Typography variant="subtitle1" component="span">
                           1x Hoodie
                        </Typography>
                     </li>
                  </ul>
               </Grid>
               <Grid item xs={12} md={6}>
                  <ul className="fm-load-list">
                     <li>
                        <CheckIcon />
                        <Typography variant="subtitle1" component="span">
                           2x Pillowcases
                        </Typography>
                     </li>
                     <li>
                        <CheckIcon />
                        <Typography variant="subtitle1" component="span">
                           1x Queen fitted sheets
                        </Typography>
                     </li>
                     <li>
                        <CheckIcon />
                        <Typography variant="subtitle1" component="span">
                           1x Queen duvet cover
                        </Typography>
                     </li>
                     <li>
                        <CheckIcon />
                        <Typography variant="subtitle1" component="span">
                           1x Queen fitted sheets
                        </Typography>
                     </li>
                     <li>
                        <CheckIcon />
                        <Typography variant="subtitle1" component="span">
                           1x Bath mat
                        </Typography>
                     </li>
                     <li>
                        <CheckIcon />
                        <Typography variant="subtitle1" component="span">
                           2x Face clothes
                        </Typography>
                     </li>
                     <li>
                        <CheckIcon />
                        <Typography variant="subtitle1" component="span">
                           1x Hand towel
                        </Typography>
                     </li>
                  </ul>
               </Grid>
            </Grid>
         </div>
      </CustomDailog>
   );
};

export const ModalTenKilos = (props) => {
   const { open, handleClose } = props;
   return (
      <CustomDailog open={open} handleClickClose={() => handleClose(false)}>
         <div className="mt-3 mx-4">
            <IconButton
               color="secondary"
               size="small"
               className="d-flex align-items-center bg-white"
               onClick={() => handleClose(false)}
               style={{ position: "absolute", right: 16 }}
            >
               <CloseOutlined />
            </IconButton>
            <Typography variant="h1" className="py-3">
               What fits 10Kg
            </Typography>
            <Typography variant="body1">{kiloGenericText}</Typography>
            <Grid container spacing={4}>
               <Grid item xs={12} md={6}>
                  <ul className="fm-load-list">
                     <li>
                        <CheckIcon />
                        <Typography variant="subtitle1" component="span">
                           4x Mens tees
                        </Typography>
                     </li>
                     <li>
                        <CheckIcon />
                        <Typography variant="subtitle1" component="span">
                           1x Jeans
                        </Typography>
                     </li>
                     <li>
                        <CheckIcon />
                        <Typography variant="subtitle1" component="span">
                           1x Shorts
                        </Typography>
                     </li>
                     <li>
                        <CheckIcon />
                        <Typography variant="subtitle1" component="span">
                           1x Sweatshirt
                        </Typography>
                     </li>
                     <li>
                        <CheckIcon />
                        <Typography variant="subtitle1" component="span">
                           4x Womens Tees
                        </Typography>
                     </li>
                     <li>
                        <CheckIcon />
                        <Typography variant="subtitle1" component="span">
                           1x Women Jeans
                        </Typography>
                     </li>
                     <li>
                        <CheckIcon />
                        <Typography variant="subtitle1" component="span">
                           1x Leggins
                        </Typography>
                     </li>
                     <li>
                        <CheckIcon />
                        <Typography variant="subtitle1" component="span">
                           1x Hoodie
                        </Typography>
                     </li>
                  </ul>
               </Grid>
               <Grid item xs={12} md={6}>
                  <ul className="fm-load-list">
                     <li>
                        <CheckIcon />
                        <Typography variant="subtitle1" component="span">
                           2x Pillowcases
                        </Typography>
                     </li>
                     <li>
                        <CheckIcon />
                        <Typography variant="subtitle1" component="span">
                           1x Queen fitted sheets
                        </Typography>
                     </li>
                     <li>
                        <CheckIcon />
                        <Typography variant="subtitle1" component="span">
                           1x Queen duvet cover
                        </Typography>
                     </li>
                     <li>
                        <CheckIcon />
                        <Typography variant="subtitle1" component="span">
                           1x Queen fitted sheets
                        </Typography>
                     </li>
                     <li>
                        <CheckIcon />
                        <Typography variant="subtitle1" component="span">
                           1x Bath mat
                        </Typography>
                     </li>
                     <li>
                        <CheckIcon />
                        <Typography variant="subtitle1" component="span">
                           2x Face clothes
                        </Typography>
                     </li>
                     <li>
                        <CheckIcon />
                        <Typography variant="subtitle1" component="span">
                           1x Hand towel
                        </Typography>
                     </li>
                     <li>
                        <CheckIcon />
                        <Typography variant="subtitle1" component="span">
                           2x Bath towels
                        </Typography>
                     </li>
                  </ul>
               </Grid>
            </Grid>
         </div>
      </CustomDailog>
   );
};

export const ModalTwelveKilos = (props) => {
   const { open, handleClose } = props;
   return (
      <CustomDailog open={open} handleClickClose={() => handleClose(false)}>
         <div className="mt-3 mx-4">
            <IconButton
               color="secondary"
               size="small"
               className="d-flex align-items-center bg-white"
               onClick={() => handleClose(false)}
               style={{ position: "absolute", right: 16 }}
            >
               <CloseOutlined />
            </IconButton>
            <Typography variant="h1" className="py-3">
               What fits 12Kg
            </Typography>
            <Typography variant="body1">{kiloGenericText}</Typography>
            <Grid container spacing={4}>
               <Grid item xs={12} md={6}>
                  <ul className="fm-load-list">
                     <li>
                        <CheckIcon />
                        <Typography variant="subtitle1" component="span">
                           1x King Size Fitted Sheet
                        </Typography>
                     </li>
                     <li>
                        <CheckIcon />
                        <Typography variant="subtitle1" component="span">
                           6x Pillowcases
                        </Typography>
                     </li>
                     <li>
                        <CheckIcon />
                        <Typography variant="subtitle1" component="span">
                           1x King Size Quilt Cover
                        </Typography>
                     </li>
                     <li>
                        <CheckIcon />
                        <Typography variant="subtitle1" component="span">
                           6x Bath Towels
                        </Typography>
                     </li>
                     <li>
                        <CheckIcon />
                        <Typography variant="subtitle1" component="span">
                           1x Bath mat
                        </Typography>
                     </li>
                     <li>
                        <CheckIcon />
                        <Typography variant="subtitle1" component="span">
                           2x Face washers
                        </Typography>
                     </li>
                     <li>
                        <CheckIcon />
                        <Typography variant="subtitle1" component="span">
                           2x Hand towel
                        </Typography>
                     </li>
                     <li>
                        <CheckIcon />
                        <Typography variant="subtitle1" component="span">
                           1x Cot Sheet
                        </Typography>
                     </li>
                  </ul>
               </Grid>
               <Grid item xs={12} md={6}>
                  <ul className="fm-load-list">
                     <li>
                        <CheckIcon />
                        <Typography variant="subtitle1" component="span">
                           1x Cot blanket
                        </Typography>
                     </li>
                     <li>
                        <CheckIcon />
                        <Typography variant="subtitle1" component="span">
                           1x Single Bed Fitted Sheet
                        </Typography>
                     </li>
                     <li>
                        <CheckIcon />
                        <Typography variant="subtitle1" component="span">
                           Single size quilt cover
                        </Typography>
                     </li>
                  </ul>
               </Grid>
            </Grid>
         </div>
      </CustomDailog>
   );
};

export const ModalFifteenKilos = (props) => {
   const { open, handleClose } = props;
   return (
      <CustomDailog open={open} handleClickClose={() => handleClose(false)}>
         <div className="mt-3 mx-4">
            <IconButton
               color="secondary"
               size="small"
               className="d-flex align-items-center bg-white"
               onClick={() => handleClose(false)}
               style={{ position: "absolute", right: 16 }}
            >
               <CloseOutlined />
            </IconButton>
            <Typography variant="h1" className="py-3">
               What fits 15Kg
            </Typography>
            <Typography variant="body1">{kiloGenericText}</Typography>
            <Grid container spacing={4}>
               <Grid item xs={12} md={6}>
                  <ul className="fm-load-list">
                     <li>
                        <CheckIcon />
                        <Typography variant="subtitle1" component="span">
                           1x King Size Fitted Sheet
                        </Typography>
                     </li>
                     <li>
                        <CheckIcon />
                        <Typography variant="subtitle1" component="span">
                           1x Queen fitted sheets
                        </Typography>
                     </li>
                     <li>
                        <CheckIcon />
                        <Typography variant="subtitle1" component="span">
                           4x Pillowcases
                        </Typography>
                     </li>
                     <li>
                        <CheckIcon />
                        <Typography variant="subtitle1" component="span">
                           1x Queen Size Quilt Cover
                        </Typography>
                     </li>
                     <li>
                        <CheckIcon />
                        <Typography variant="subtitle1" component="span">
                           1x King Size Quilt Cover
                        </Typography>
                     </li>
                     <li>
                        <CheckIcon />
                        <Typography variant="subtitle1" component="span">
                           1x Bath mat
                        </Typography>
                     </li>
                     <li>
                        <CheckIcon />
                        <Typography variant="subtitle1" component="span">
                           4x Face washers
                        </Typography>
                     </li>
                     <li>
                        <CheckIcon />
                        <Typography variant="subtitle1" component="span">
                           1x Hand towel
                        </Typography>
                     </li>
                  </ul>
               </Grid>
               <Grid item xs={12} md={6}>
                  <ul className="fm-load-list">
                     <li>
                        <CheckIcon />
                        <Typography variant="subtitle1" component="span">
                           6x bath towels
                        </Typography>
                     </li>
                     <li>
                        <CheckIcon />
                        <Typography variant="subtitle1" component="span">
                           1x Mens Sweatpants
                        </Typography>
                     </li>
                     <li>
                        <CheckIcon />
                        <Typography variant="subtitle1" component="span">
                           1x Mens hoodie
                        </Typography>
                     </li>
                     <li>
                        <CheckIcon />
                        <Typography variant="subtitle1" component="span">
                           1x Womens hoodie
                        </Typography>
                     </li>
                     <li>
                        <CheckIcon />
                        <Typography variant="subtitle1" component="span">
                           1x Women sweatshirt
                        </Typography>
                     </li>
                     <li>
                        <CheckIcon />
                        <Typography variant="subtitle1" component="span">
                           4x Kids Tees
                        </Typography>
                     </li>
                     <li>
                        <CheckIcon />
                        <Typography variant="subtitle1" component="span">
                           4x Kids short
                        </Typography>
                     </li>
                  </ul>
               </Grid>
            </Grid>
         </div>
      </CustomDailog>
   );
};

export const ModalHowWeCharge = (props) => {
   const { open, handleClose } = props;
   return (
      <CustomDailog open={open} handleClickClose={() => handleClose(false)}>
         <div className="mt-3 mx-4">
            <IconButton
               color="secondary"
               size="small"
               className="d-flex align-items-center bg-white"
               onClick={() => handleClose(false)}
               style={{ position: "absolute", right: 16 }}
            >
               <CloseOutlined />
            </IconButton>
            <Typography variant="h1" className="py-3">
               How we charge you
            </Typography>
            <Typography variant="subtitle1">
               We weigh your clothes in your standard washing and count your
               heavy larger items.
            </Typography>
            <ul className="fm-generic-list">
               <li>
                  <Typography variant="body1" component="span">
                     Clothes, sheets, and towels are weighed and charged by the
                     kilo (and we round up to the nearest 500g). We offer free
                     delivery and a discounted rate when you sign up to a weekly
                     or fortnightly wash. To be eligible for the ‘Repeat’ plan
                     you need to lock in four consecutive weekly or fortnightly
                     orders.
                  </Typography>
               </li>
               <li>
                  <Typography variant="body1" component="span">
                     Pillows, doonas, mattress protectors and other bulky items
                     are charged per item.
                  </Typography>
               </li>
               <li>
                  <Typography variant="body1" component="span">
                     We get you to guestimate the amount of laundry you need
                     done so you can get an understanding of price. If your
                     actual Load is greater than your booked Load, then we will
                     charge you the top up difference.
                  </Typography>
               </li>
               <li>
                  <Typography variant="body1" component="span">
                     The minimum order value is $35 (excluding delivery fee).
                  </Typography>
               </li>
            </ul>
         </div>
      </CustomDailog>
   );
};
