import { HelpOutlined } from "@mui/icons-material";
import {
   Typography,
   Grid,
   Box,
   Divider,
   List,
   ListItem,
   Switch,
   Chip,
} from "@mui/material";
import { Container } from "@mui/system";
import CustomizedSlider from "../../shared/RangeSlider";
import { useState } from "react";
import CartItem from "../../shared/CartItem";
import { getProducts } from "../../../cleancloud/getProducts";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import {
   addToCart,
   removeFromCart,
} from "../../../store/products/storeProducts";
import { changeSubTotal } from "../../../store/order/storeOrder";
import { ScrollRestoration } from "react-router-dom";
import {
   ModalSevenKilos,
   ModalEightKilos,
   ModalNineKilos,
   ModalTenKilos,
   ModalTwelveKilos,
   ModalFifteenKilos,
   ModalHowWeCharge,
} from "../../shared/ModalKilos";

//Images
import imgBag7Kg from "../../../assets/Foam-Repeat-7.jpg";
import imgBag8Kg from "../../../assets/Foam-Repeat-8.jpg";
import imgBag9Kg from "../../../assets/Foam-Repeat-9.jpg";
import imgBag10Kg from "../../../assets/Foam-Repeat-10.jpg";
import imgBag12Kg from "../../../assets/Foam-Repeat-12.jpg";
import imgBag15Kg from "../../../assets/Foam-Repeat-15.jpg";
import imgBasket7Kg from "../../../assets/7-Kg-Hip-Hugger.jpg";
import imgBasket8Kg from "../../../assets/8-Kg-Hip-Hugger.jpg";
import imgBasket9Kg from "../../../assets/9-Kg-Hip-Hugger.jpg";
import imgBasket10Kg from "../../../assets/10-Kg-Hip-Hugger.jpg";
import imgBasket12Kg from "../../../assets/12-Kg-Hip-Hugger.jpg";
import imgBasket15Kg from "../../../assets/15-Kg-Hip-Hugger.jpg";
import imgNoWashing from "../../../assets/streamlinehq-laundry-robot-robot-800.png";

export default function Load() {
   const { subscription } = useSelector((store) => store.subscription);
   const { basketWeight, products } = useSelector((store) => store.products);
   const [loading, setLoading] = useState(true);
   const [addons, setAddons] = useState([]);
   const [heavyItems, setHeavyItems] = useState([]);
   const [sliderData, setSliderData] = useState([]);
   const [sliderMarks, setSliderMarks] = useState([{ label: "", value: 0 }]);
   const [sevenKgModalIsOpen, setSevenKgModalIsOpen] = useState(false);
   const [eightKgModalIsOpen, setEightKgModalIsOpen] = useState(false);
   const [nineKgModalIsOpen, setNineKgModalIsOpen] = useState(false);
   const [tenKgModalIsOpen, setTenKgModalIsOpen] = useState(false);
   const [twelveKgModalIsOpen, setTwelveKgModalIsOpen] = useState(false);
   const [fifteenKgModalIsOpen, setFifteenKgModalIsOpen] = useState(false);
   const [howWeChargeModalIsOpen, setHowWeChargeModalIsOpen] = useState(false);

   const dispatch = useDispatch();

   const sliderLocalData = {
      7: {
         text1: "Perfect for singles looking to outsource their laundry pile",
      },
      8: {
         text1: "Perfect for singles looking to outsource their laundry pile",
      },
      9: {
         text1: "Suited to professional couples looking to save time and money",
      },
      10: {
         text1: "Suited to professional couples  looking to save time and money",
      },
      12: {
         text1: "Suited to families wanting to refresh all bed linen and towels",
      },
      15: {
         text1: "Suited to families wanting to catch up on laundry and save time",
      },
   };

   const sortSliderData = (data) => {
      setSliderData(data);
      const weightsWithoutUnit = data.map((item, index) => ({
         label: "",
         value: index + 1,
      }));
      setSliderMarks(weightsWithoutUnit);
   };

   const addProduct = (e, item) => {
      e.target.checked
         ? dispatch(addToCart(item))
         : dispatch(removeFromCart(item));
   };

   const filterHeavyItems = (data) => {
      const id = data.SectionsMap.filter(
         (item) => item.name.toLowerCase() === "bedding"
      )[0].id;
      setHeavyItems(data.Products.filter((item) => item.section === id));
   };

   const filterAddons = (data) => {
      const id = data.SectionsMap.filter(
         (item) => item.name.toLowerCase() === "add-ons"
      )[0].id;
      setAddons(data.Products.filter((item) => item.section === id));
   };

   const filterSliderData = (data) => {
      const id = subscription
         ? data.SectionsMap.filter(
              (item) => item.name.toLowerCase() === "subscriptions"
           )[0].id
         : data.SectionsMap.filter(
              (item) => item.name.toLowerCase() === "online"
           )[0].id;
      sortSliderData(data.Products.filter((item) => item.section === id));
   };
   // eslint-disable-next-line
   const requiredProducts = async () => {
      try {
         setLoading(true);
         const response = await getProducts();
         setLoading(false);
         filterHeavyItems(response);
         filterSliderData(response);
         filterAddons(response);
      } catch (e) {
         console.log(e);
      }
   };

   useEffect(() => {
      requiredProducts();
      dispatch(changeSubTotal(0));
      // eslint-disable-next-line
   }, []);

   const mainImage = () => {
      let imageUrl = "";
      switch (basketWeight) {
         case "7kg":
            imageUrl =
               products.filter(
                  (item) => item.section === "7" || item.section === "17"
               )[0].section === "7"
                  ? imgBag7Kg
                  : imgBasket7Kg;
            break;
         case "8kg":
            imageUrl =
               products.filter(
                  (item) => item.section === "7" || item.section === "17"
               )[0].section === "7"
                  ? imgBag8Kg
                  : imgBasket8Kg;
            break;
         case "9kg":
            imageUrl =
               products.filter(
                  (item) => item.section === "7" || item.section === "17"
               )[0].section === "7"
                  ? imgBag9Kg
                  : imgBasket9Kg;
            break;
         case "10kg":
            imageUrl =
               products.filter(
                  (item) => item.section === "7" || item.section === "17"
               )[0].section === "7"
                  ? imgBag10Kg
                  : imgBasket10Kg;
            break;
         case "12kg":
            imageUrl =
               products.filter(
                  (item) => item.section === "7" || item.section === "17"
               )[0].section === "7"
                  ? imgBag12Kg
                  : imgBasket12Kg;
            break;
         case "15kg":
            imageUrl =
               products.filter(
                  (item) => item.section === "7" || item.section === "17"
               )[0].section === "7"
                  ? imgBag15Kg
                  : imgBasket15Kg;
            break;
         default:
            imageUrl = imgNoWashing;
      }
      return imageUrl;
   };

   const infoText = (
      <>
         <Typography variant="body2" className="my-2">
            Standard washing does not include heavy items such as pillows,
            doonas or mattress protectors. Heavy items are charged separately
            and can be found in the section below.
         </Typography>
         <Typography
            variant="body2"
            className="my-2"
            style={{
               fontWeight: 700,
               textDecoration: "underline",
               display: "inline-block",
               cursor: "pointer",
            }}
            onClick={() => setHowWeChargeModalIsOpen(true)}
         >
            Click here to know more about how we charge.
         </Typography>
      </>
   );
   return (
      <>
         <ScrollRestoration />
         <Container maxWidth={"md"} sx={{ padding: 0 }}>
            <Grid container spacing={{ xs: 2, md: 3 }} justifyContent="center">
               <Grid item className="text-center ">
                  <Typography variant="h1" align="center" className="mb-4">
                     Load
                  </Typography>
                  <Typography variant="body1" className="mb-4">
                     Use the slider to roughly estimate how much laundry you
                     have.
                  </Typography>
               </Grid>
               <Grid item xs={12}>
                  <Box
                     sx={{ borderRadius: "30px", background: "#DAEBEB" }}
                     className="p-3"
                  >
                     <Grid container spacing={3} alignItems="center">
                        <Grid item xs={12} md={6}>
                          <div className="m-3 fm-kilo-wrapper">
                              {products.filter((item) => item.section === "7")
                                 .length ? (
                                 <Chip
                                    variant="filled"
                                    color="secondary"
                                    label="FOAM Repeat"
                                    sx={{
                                       marginBottom: "16px",
                                       fontWeight: 500,
                                    }}
                                 />
                              ) : null}
                              {products.filter((item) => item.section === "17")
                                 .length ? (
                                 <Chip
                                    variant="filled"
                                    label="Wash, Dry & Fold"
                                    sx={{
                                       color: "white",
                                       marginBottom: "16px;",
                                       fontWeight: 500,
                                    }}
                                 />
                              ) : null}
                              <Typography
                                 variant="h5"
                                 style={{
                                    display: "flex",
                                    alignItems: "center",
                                 }}
                              >
                                 {products.filter(
                                    (item) =>
                                       item.section === "7" ||
                                       item.section === "17"
                                 ).length ? (
                                    <>
                                       {basketWeight} Capacity ($
                                       {
                                          products.filter(
                                             (item) =>
                                                item.section === "7" ||
                                                item.section === "17"
                                          )[0].price
                                       }
                                       )
                                       <HelpOutlined
                                          className="mx-2"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                             switch (basketWeight) {
                                                case "7kg":
                                                   setSevenKgModalIsOpen(true);
                                                   break;
                                                case "8kg":
                                                   setEightKgModalIsOpen(true);
                                                   break;
                                                case "9kg":
                                                   setNineKgModalIsOpen(true);
                                                   break;
                                                case "10kg":
                                                   setTenKgModalIsOpen(true);
                                                   break;
                                                case "12kg":
                                                   setTwelveKgModalIsOpen(true);
                                                   break;
                                                case "15kg":
                                                   setFifteenKgModalIsOpen(
                                                      true
                                                   );
                                                   break;
                                                default:
                                                   setSevenKgModalIsOpen(false);
                                                   setEightKgModalIsOpen(false);
                                                   setNineKgModalIsOpen(false);
                                                   setTenKgModalIsOpen(false);
                                                   setTwelveKgModalIsOpen(
                                                      false
                                                   );
                                                   setFifteenKgModalIsOpen(
                                                      false
                                                   );
                                             }
                                          }}
                                       />
                                    </>
                                 ) : (
                                    <>{basketWeight} Capacity</>
                                 )}
                              </Typography>
                              <Typography className="mt-2">
                                 {basketWeight === "7kg"
                                    ? sliderLocalData[8].text1
                                    : basketWeight === "8kg"
                                    ? sliderLocalData[7].text1
                                    : basketWeight === "9kg"
                                    ? sliderLocalData[9].text1
                                    : basketWeight === "10kg"
                                    ? sliderLocalData[10].text1
                                    : basketWeight === "12kg"
                                    ? sliderLocalData[12].text1
                                    : basketWeight === "15kg"
                                    ? sliderLocalData[15].text1
                                    : ""}
                              </Typography>
                              <ModalSevenKilos
                                 open={sevenKgModalIsOpen}
                                 handleClose={setSevenKgModalIsOpen}
                              />
                              <ModalEightKilos
                                 open={eightKgModalIsOpen}
                                 handleClose={setEightKgModalIsOpen}
                              />
                              <ModalNineKilos
                                 open={nineKgModalIsOpen}
                                 handleClose={setNineKgModalIsOpen}
                              />
                              <ModalTenKilos
                                 open={tenKgModalIsOpen}
                                 handleClose={setTenKgModalIsOpen}
                              />
                              <ModalTwelveKilos
                                 open={twelveKgModalIsOpen}
                                 handleClose={setTwelveKgModalIsOpen}
                              />
                              <ModalFifteenKilos
                                 open={fifteenKgModalIsOpen}
                                 handleClose={setFifteenKgModalIsOpen}
                              />
                              <ModalHowWeCharge
                                 open={howWeChargeModalIsOpen}
                                 handleClose={setHowWeChargeModalIsOpen}
                              />
                              <Box
                                 sx={{ display: { xs: "none", md: "block" } }}
                              >
                                 <Typography className="my-2">---</Typography>
                                 {infoText}
                              </Box>
                           </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                           <div
                              className="bg-white p-2 arrow-left"
                              style={{ borderRadius: 20 }}
                           >
                              <div className="d-flex flex-column align-items-center jusitfy-content-end my-1">
                                 <img
                                    src={mainImage()}
                                    className="fm-vessel-image"
                                    alt="bucket-clothes"
                                    width="250"
                                 />
                              </div>
                           </div>
                        </Grid>
                     </Grid>
                  </Box>
               </Grid>
               <Grid
                  item
                  xs={12}
                  className="mt-2"
                  sx={{ display: "flex", justifyContent: "center" }}
               >
                  <CustomizedSlider
                     marks={sliderMarks}
                     sliderData={sliderData}
                  />
               </Grid>
               <Grid item xs={12} sx={{ display: { xs: "block", md: "none" } }} className="fm-kilo-wrapper">
                  {infoText}
               </Grid>
               <Grid item xs={12} className="text-center mt-5">
                  <Typography variant="h1" align="center" className="mb-4">
                     Select bulky items
                  </Typography>
                  <Typography align="center" variant="body1" className="mb-4">
                     Bulky items are not included in your standard washing.
                  </Typography>
               </Grid>
               {!loading ? (
                  heavyItems.length ? (
                     heavyItems.map((item, i) => (
                        <Grid item xs={6} sm={4} md={3} key={i}>
                           <CartItem item={item} />
                        </Grid>
                     ))
                  ) : (
                     <div className="d-flex justify-content-center mt-3">
                        <Typography variant="h4" align="center">
                           No Items to show 😟
                        </Typography>
                     </div>
                  )
               ) : (
                  <Grid item>
                     <Spinner animation="grow" size="large" />
                  </Grid>
               )}
               <Grid xs={12} item>
                  <Typography variant="h1" align="center" className="mb-4 mt-5">
                     Select your Add Ons
                  </Typography>
                  <Typography align="center" variant="body1">
                     Extra services you can add to your order
                  </Typography>
               </Grid>
               <Grid item xs={12}>
                  {loading ? (
                     <div className="d-flex align-items-center justify-content-center">
                        <Spinner animation="grow" />
                     </div>
                  ) : addons.length ? (
                     <List>
                        {addons.map((item, i) => {
                           return (
                              <div key={item.id}>
                                 <ListItem className="d-flex align-items-center justify-content-between">
                                    <Typography>
                                       {item.name} (${item.price})
                                    </Typography>
                                    <Switch
                                       color="secondary"
                                       onChange={(e) => addProduct(e, item)}
                                       checked={Boolean(
                                          products.filter(
                                             (ele) => item.id === ele.id
                                          ).length
                                       )}
                                    />
                                 </ListItem>
                                 {addons.length - 1 !== i ? (
                                    <Divider
                                       sx={{ border: "1px solid #bbb" }}
                                    />
                                 ) : (
                                    ""
                                 )}
                              </div>
                           );
                        })}
                     </List>
                  ) : (
                     <div className="d-flex justify-content-center">
                        <Typography variant="h4" align="center">
                           No Items to show 😟
                        </Typography>
                     </div>
                  )}
               </Grid>
            </Grid>
         </Container>
      </>
   );
}
